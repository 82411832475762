.work-experiences-wrap {
  .content-wrap {
    flex-direction: column;
    flex: 1;

    .work-wrap {
      margin-bottom: 25px;
      position: relative;

      .block{
        background: #3393cb;
        width: 15px;
        height: 34px;
        position: absolute;
        left: -25px;
        top: 4.3px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .company-name {
          font-weight: bold;
          font-size: 18px;
          padding-right: 20px;
        }

        .date {
          color: #3393cb;
          font-weight: bold;
        }
      }

      .position {
        font-weight: bold;
      }


      .job-desc {
        margin-top: 5px;
        white-space: pre-line;

        span {
          color: #3393cb;
        }
      }
    }
  }
}
