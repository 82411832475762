@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  min-width: auto;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
  background: #eeeeee;


  .container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: auto;
    position: relative;
    padding: 1em;

    .ttl {
      font-size: 23px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}
