.contacts-wrap {
  div {
    text-align: right;
    margin-bottom: 15px;

    span {
      position: relative;
      top: -4px;
    }

    .icon {
      margin: 0 0 0 15px;
      font-size: 20px;
      text-align: center;
      color: #3393cb;

    }
  }
}
