.skills-wrap {
  .content-wrap {
    display: flex;
    flex-direction: column;

    .skill-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 25px;
      align-items: center;
      margin-bottom: 25px;

      .name {
        flex: 0.4;
        text-align: right;
        margin-right: 20px;
      }

      .lvl-wrap {
        flex: 0.6;
        height: 100%;
        border: #64bdf2 2px solid;

        .lvl {
          background: #64bdf2;
          display: block;
          height: 100%;
          color: #FFF;
          font-size: 8px;
          text-align: right;
          padding-right: 10px;
          font-weight: bold;
        }
      }

    }

    .other-skills {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
